import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Configuracion } from "../core/models/configuracion.model";
import { ConfiguracionService } from "../core/services/configuracion.service";

@Component({
  selector: "app-configuracion",
  templateUrl: "./configuracion.component.html",
})
export class ConfiguracionComponent implements OnInit {
  formGroup: FormGroup;
  id: number;
  errorMessage: any;
  submitted = false;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ConfiguracionService
  ) {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      id: [0],
      urlVideo: ["", Validators.required],
    });
  }

  get f() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.apiService.getById(1).subscribe(
      (resp: any) => {
        const model = resp as Configuracion;
        console.log(model);
        this.setForm(model);
      },
      (error) => {
        this.errorMessage = error;
        console.log(error);
      },
      () => (this.loading = false)
    );
  }

  setForm(form: Configuracion) {
    this.formGroup.get("id").setValue(form.id);
    this.formGroup.get("urlVideo").setValue(form.urlVideo);
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    this.apiService.update(this.formGroup.value).subscribe(
      () => {},
      (error) => (this.errorMessage = error),
      () => (this.loading = false)
    );
  }
}
