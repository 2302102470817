import { Component, OnInit, EventEmitter, Output, Input, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsuarioService } from '../core/services/usuario.service';
import { Rol } from '../core/models/rol.model';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})

export class UsuarioComponent {

  registerForm: FormGroup;
  title: string = "Nuevo";
  id: number;
  errorMessage: any;
  submitted = false;
  roles: Rol[];
  public loading: boolean;

  constructor(private fb: FormBuilder, private apiService: UsuarioService, private router: Router, private avRoute: ActivatedRoute) {

    if (this.avRoute.snapshot.params["id"]) {
      this.id = this.avRoute.snapshot.params["id"];
    }

    this.registerForm = this.fb.group({
      id: 0,
      nombre: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      telefono: [''],
      rolId: 0,
      confirmPassword: ''
    })
  }


  ngOnInit() {
    this.loading = true;

    this.apiService.getRoles().subscribe(
      data => {
        this.roles = data;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );

    if (this.id > 0) {
      this.title = "Editar";
      this.apiService.getById(this.id).subscribe(
        resp => 
          this.registerForm.setValue(resp),
        error => 
          console.log(error)
      );
    };

    this.loading = false;
  }


  get f() {
    return this.registerForm.controls;
  }


  save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    if (this.title == "Nuevo") {
      this.loading = true;
      this.apiService.add(this.registerForm.value).subscribe(
        data => this.router.navigate(['/usuarios']),
        error => this.errorMessage = error,
        () => this.loading = false
      );
    }
    else if (this.title == "Editar") {
      this.loading = true
      this.apiService.update(this.registerForm.value).subscribe(
        data => this.router.navigate(['/usuarios']),
        error => this.errorMessage = error,
        () => this.loading = false
      );
    }
  }


  cancel() {
    this.router.navigate(['/usuarios']);
  }


  getRoles() {

  }

}
