import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UploadComponent } from '../../shared/components/upload/upload.component';
import { AppRoutingModule } from '../../../app/app-routing.module';
import { ServiceCapService } from '../services/servicecap.service';
import { TipoMaquinariaService } from '../services/tipomaquinaria.service';
import { MaquinariaService } from '../services/maquinaria.service';
import { MaquinariaFormComponent } from '../../maquinarias/maquinaria-form/maquinaria-form.component';
import { MaquinariaListComponent } from '../../maquinarias/maquinaria-list/maquinaria-list.component';
import { ServicecapFormComponent } from '../../servicecaps/servicecap-form/servicecap-form.component';
import { ServicecapListComponent } from '../../servicecaps/servicecap-list/servicecap-list.component';
import { TipomaquinariaListComponent } from '../../tipomaquinaria/tipomaquinaria-list/tipomaquinaria-list.component';
import { TipomaquinariaFormComponent } from '../../tipomaquinaria/tipomaquinaria-form/tipomaquinaria-form.component';
import { ComponentModule } from '../../shared/components/component.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
    imports: [

        CommonModule,
        AppRoutingModule,
        NgxDatatableModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TabsModule.forRoot(),
        ComponentModule
    ],
    providers:
        [
            ServiceCapService,
            TipoMaquinariaService,
            MaquinariaService
        ],

    declarations:
        [
            MaquinariaFormComponent,
            MaquinariaListComponent,
            ServicecapFormComponent,
            ServicecapListComponent,
            TipomaquinariaFormComponent,
            TipomaquinariaListComponent,
            
        ]
})


export class MaquinaModule {
}
