import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ServiceCap } from 'src/app/core/models/servicecap.model';
import { ServiceCapService } from 'src/app/core/services/servicecap.service';

@Component({
    selector: 'app-servicecap-list',
    templateUrl: './servicecap-list.component.html',
    styleUrls: ['./servicecap-list.component.scss']
})
/** servicecap-list component*/
export class ServicecapListComponent {
    public rows: ServiceCap[];
    public temp: ServiceCap[];
    public loading: boolean;
  
    constructor(private apiService: ServiceCapService) { }
  
    @ViewChild(DatatableComponent) table: DatatableComponent;
  
    ngOnInit() {
      this.getAll();
    }
    
    getAll() {
      this.loading = true;
  
      this.apiService.getAll().subscribe(
        data => {
          this.rows = data;
          this.temp = [...data];
          this.loading = false;
        },
        () => this.loading = false);
    }
  
    updateFilter(event) {
      const val = event.target.value.toLowerCase();
  
      const temp = this.temp.filter(function (d) {
        return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
      this.table.offset = 0;
    }
  
    delete(id: number) {
      if (confirm('Esta seguro de eliminar el registro?')) {
        this.apiService.delete(id).subscribe(() => {
          this.getAll();
        });
      }
    }
   }
  
  