import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "../app/Auth/login/login.component";
import { UsuariosComponent } from "../app/usuarios/usuarios.component";
import { UsuarioComponent } from "../app/usuarios/usuario.component";
import { RolesComponent } from "../app/roles/roles.component";
import { RolComponent } from "../app/roles/rol.component";
import { AuthorizatedGuard } from "./Auth/auth.guard";
import { LoginLayoutComponent } from "../app/shared/layout/login.layout/login-layout.component";
import { HomeLayoutComponent } from "../app/shared/layout/home.layout/home-layout.component";
import { ContactosComponent } from "./contactos/contactos.component";
import { EmpresaListComponent } from "./empresas/empresa-list/empresa-list.component";
import { EmpresaComponent } from "./empresas/empresa-form/empresa-form.component";
import { MaquinariaListComponent } from "./maquinarias/maquinaria-list/maquinaria-list.component";
import { MaquinariaFormComponent } from "./maquinarias/maquinaria-form/maquinaria-form.component";
import { EventoListComponent } from "./eventos/evento-list/evento-list.component";
import { EventoFormComponent } from "./eventos/evento-form/evento-form.component";
import { NovedadesComponent } from "./novedades/novedad-list/novedades.component";
import { NovedadComponent } from "./novedades/novedad-form/novedad.component";
import { ServicecapFormComponent } from "./servicecaps/servicecap-form/servicecap-form.component";
import { ServicecapListComponent } from "./servicecaps/servicecap-list/servicecap-list.component";
import { FrontEndLayoutComponent } from "./shared/layout/front-end-layout/front-end-layout.component";
import { HomeComponent } from "./home/home/home.component";
import { TipomaquinariaListComponent } from "./tipomaquinaria/tipomaquinaria-list/tipomaquinaria-list.component";
import { TipomaquinariaFormComponent } from "./tipomaquinaria/tipomaquinaria-form/tipomaquinaria-form.component";
import { TiponovedadListComponent } from "./tipoNovedad/tiponovedad-list/tiponovedad-list.component";
import { TiponovedadFormComponent } from "./tipoNovedad/tiponovedad-form/tiponovedad-form.component";
import { ConfiguracionComponent } from "./configuracion/configuracion.component";

const routes1: Routes = [
  {
    path: "",
    component: HomeLayoutComponent,
    canActivate: [AuthorizatedGuard],
    children: [
      { path: "empresas", component: EmpresaListComponent },
      { path: "empresa/:id", component: EmpresaComponent },
      { path: "usuarios", component: UsuariosComponent },
      { path: "usuario/:id", component: UsuarioComponent },
      { path: "roles", component: RolesComponent },
      { path: "rol/:id", component: RolComponent },
      { path: "contactos", component: ContactosComponent },
      { path: "maquinarias", component: MaquinariaListComponent },
      { path: "maquinaria/:id", component: MaquinariaFormComponent },
      { path: "eventos", component: EventoListComponent },
      { path: "evento/:id", component: EventoFormComponent },
      { path: "novedades", component: NovedadesComponent },
      { path: "novedad/:id", component: NovedadComponent },
      { path: "servicecaps", component: ServicecapListComponent },
      { path: "servicecap/:id", component: ServicecapFormComponent },
      { path: "tipomaquinarias", component: TipomaquinariaListComponent },
      { path: "tipomaquinaria/:id", component: TipomaquinariaFormComponent },
      { path: "tiponovedades", component: TiponovedadListComponent },
      { path: "tiponovedad/:id", component: TiponovedadFormComponent },
      { path: "configuracion", component: ConfiguracionComponent },
    ],
  },

  {
    path: "",
    component: LoginLayoutComponent,
    children: [{ path: "login", component: LoginComponent }],
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes1)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
