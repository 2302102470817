import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';import { BaseService } from './base.service';
import { TipoMaquinaria } from '../models/tipoMaquinaria.model';


@Injectable({
  providedIn: 'root'
})

export class TipoMaquinariaService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
  }

    add(body: TipoMaquinaria) {

      return this.http.post<TipoMaquinaria>(this.baseUrl + 'api/TipoMaquinaria/Create', body)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }));

  }

    update(body: TipoMaquinaria) {
      return this.http.put<TipoMaquinaria>(this.baseUrl + 'api/TipoMaquinaria/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  delete(id: Number) {
      return this.http.delete(this.baseUrl + "api/TipoMaquinaria/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }


  getById(id: number) {
      return this.http.get<any>(this.baseUrl + 'api/TipoMaquinaria/GetById/' + id);
  }

  getAll() {
      return this.http.get<any>(this.baseUrl + 'api/TipoMaquinaria/GetAll');
  }

 
  getIdiomas() {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetIdiomas');
  }
}
