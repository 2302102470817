import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from '../core/services/usuario.service';
import { Usuario } from '../core/models/usuario.model';
import { ExcelService } from '../core/helpers/ExcelService';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})

export class UsuariosComponent {

  public rows: Usuario[];
  public temp: Usuario[];
  public loading: boolean;

  constructor(http: HttpClient, private apiService: UsuarioService, private excelService: ExcelService) { }

  @ViewChild(DatatableComponent) table: DatatableComponent;


  ngOnInit() {
    this.getAll();
  }


  getAll() {
    this.loading = true;
    this.apiService.getAll().subscribe(
      data => {
        this.rows = data;
        this.temp = [...data];
      },
      error => console.log(error),
      () => this.loading = false
    );
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(
      function (d) {
        return (
          d.nombre.toLowerCase().indexOf(val) !== -1
          || d.telefono.toLowerCase().indexOf(val) !== -1
          || d.email.toLowerCase().indexOf(val) !== -1
          || !val
        );
      }
    );
    this.rows = temp;
    this.table.offset = 0;
  }

  delete(id: number) {
    if (confirm('Esta seguro de eliminar el registro?')) {
      this.apiService.delete(id).subscribe(
        res => this.getAll()
      );
    }
  }


  exportAsXLSX(): void {
    let newJson = this.rows.map(
      rec => {
        return {
          'Usuario': rec.nombre,
          'Teléfono': rec.telefono,
          'Email': rec.email
        }
      }
    );
    this.excelService.exportAsExcelFile(newJson, 'usuarios');
  }

}
