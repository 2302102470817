import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';



import { BaseService } from './base.service';
import { ServiceCap } from '../models/servicecap.model';


@Injectable({
  providedIn: 'root'
})

export class ServiceCapService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  add(body: ServiceCap) {

    return this.http.post<ServiceCap>(this.baseUrl + 'api/ServiceCap/Create', body)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }));

  }

  update(body: ServiceCap) {
    return this.http.put<ServiceCap>(this.baseUrl + 'api/ServiceCap/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  delete(id: Number) {
    return this.http.delete(this.baseUrl + "api/ServiceCap/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }


  getById(id: number) {
    return this.http.get<any>(this.baseUrl + 'api/ServiceCap/GetById/' + id);
  }

  getAll() {
    return this.http.get<any>(this.baseUrl + 'api/ServiceCap/GetAll');
  }

 
  getIdiomas() {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetIdiomas');
  }
  
  getProvincias() {
    return this.http.get<any>(this.baseUrl + 'api/ServiceCap/GetProvincias');
  }
}
