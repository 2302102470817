import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { EmpresaService } from '../services/empresa.service';
import { EmpresaListComponent } from '../../empresas/empresa-list/empresa-list.component';
import { EmpresaComponent } from '../../empresas/empresa-form/empresa-form.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [

    CommonModule,
    AppRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports:
    [
      EmpresaListComponent,
      EmpresaComponent
    ],
  providers:
    [
      EmpresaService
    ],

  declarations:
    [
      EmpresaComponent,
      EmpresaListComponent
    ]
})


export class EmpresaModule {
}
