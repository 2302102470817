import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Headers, Http } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



import { BaseService } from './base.service';
import { Contacto } from '../models/contacto.model';


@Injectable({
  providedIn: 'root'
})
export class ContactoService extends BaseService {
  [x: string]: any;



  constructor(protected http: HttpClient) {
    super(http);
  }


  getById(id: number) {
    return this.http.get<Contacto>(this.baseUrl + 'api/Contacto/GetById/' + id);
  }

  getAll() {
    return this.http.get<Contacto[]>(this.baseUrl + 'api/Contacto/GetAll');
  }

  ExportExcel() {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(this.baseUrl + 'api/Contacto/DownloadFile', httpOptions);
  }
}
