import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';import { BaseService } from './base.service';
import { TipoNovedad } from '../models/TipoNovedad.model';


@Injectable({
  providedIn: 'root'
})

export class TipoNovedadService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
  }

    add(body: TipoNovedad) {

      return this.http.post<TipoNovedad>(this.baseUrl + 'api/TipoNovedad/Create', body)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }));

  }

    update(body: TipoNovedad) {
      return this.http.put<TipoNovedad>(this.baseUrl + 'api/TipoNovedad/Update', body)
      .pipe(
        map(res => {
          console.log('');
          return res;
        }));
  }

  delete(id: Number) {
      return this.http.delete(this.baseUrl + 'api/TipoNovedad/Delete/' + id)
      .pipe(
        map(res => {
          console.log('');
          return res;
        }));
  }


  getById(id: number) {
      return this.http.get<any>(this.baseUrl + 'api/TipoNovedad/GetById/' + id);
  }

  getAll() {
      return this.http.get<any>(this.baseUrl + 'api/TipoNovedad/GetAll');
  }

  getIdiomas() {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetIdiomas');
  }
}
