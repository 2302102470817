import { DatePipe } from '@angular/common';

export class Formatter {

  constructor(private datePipe: DatePipe) { }

  formatFecha(fecha: Date): any {
    return ({ year: Number(this.datePipe.transform(fecha, 'yyyy')), month: Number(this.datePipe.transform(fecha, 'MM')), day: Number(this.datePipe.transform(fecha, 'dd')) });
  }



  FormatDate(iDate: Date) {
    var inputDate = new Date(iDate);
    return this.datePipe.transform(inputDate, 'yyyy') + '-' + (this.datePipe.transform(inputDate, 'MM')) + '-' + (this.datePipe.transform(inputDate, 'dd'));
  }

  formatFechaJ(fecha: any): Date {
    return new Date(fecha.year, fecha.month - 1, fecha.day);
  }

  formatPhoneNumber(area: string, number: string) {
    let celArea = "";
    let celNum = "";
    try { celArea = area.toString() } catch{ }
    try { celNum = number.toString() } catch{ }
    return celArea + " " + celNum;
  }

  getPhoneNumber(number: string) {

    let area = "";
    let numero = "";
    let arrayNumber = [];

    try {
      arrayNumber = number.split(" ");
      try { area = arrayNumber[0]; } catch{}
      try { numero = arrayNumber[1]; } catch{}
    } catch{ }

    return { area: area, numero: numero };
  }
}
