import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Headers, Http } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { Novedad } from '../models/novedad.model';
import { BaseService } from './base.service';
import { Maquinaria } from '../models/maquinaria.model';


@Injectable({
  providedIn: 'root'
})
export class MaquinariaService extends BaseService {
  [x: string]: any;



  constructor(protected http: HttpClient) {
    super(http);
  }

    add(body: Maquinaria) {

      return this.http.post<Maquinaria>(this.baseUrl + 'api/Maquinaria/Create', body)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }));

  }

  update(body: Maquinaria) {
      return this.http.put<Maquinaria>(this.baseUrl + 'api/Maquinaria/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  delete(id: Number) {
      return this.http.delete(this.baseUrl + "api/Maquinaria/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }
  deleteImagen(id: Number) {
      return this.http.delete(this.baseUrl + "api/Maquinaria/DeleteImagen/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  marcarPrincipal(id: Number) {
    return this.http.delete(this.baseUrl + "api/Maquinaria/UpdateImagen/" + id)
    .pipe(
      map(res => {
        console.log("");
        return res;
      }));
}
  getById(id: number) {
      return this.http.get<any>(this.baseUrl + 'api/Maquinaria/GetById/' + id);
  }
  
  getImagenes(id: number) {
    return this.http.get<any>(this.baseUrl + 'api/Maquinaria/GetImagenes/' + id);
  }
  getAll() {
      return this.http.get<any>(this.baseUrl + 'api/Maquinaria/GetAll');
  }

 
  getIdiomas() {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetIdiomas');
    }

    getTipos() {
        return this.http.get<any>(this.baseUrl + 'api/Maquinaria/GetTipos');
    }

    updateDocumento(fileToUpload: File, maquinariaId:number, documento: string) {

      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
  
      return this.http.post(this.baseUrl +  'api/Maquinaria/UpdateDocumento/' + maquinariaId + "/" + documento, formData, { reportProgress: true, observe: 'events' });
  
    }

    exportPdf(maquinariaId:number, documento: string) {

      const httpOptions = {
        responseType: 'blob' as 'json'
      };
  
      return this.http
        .get<any>(this.baseUrl +  'api/Maquinaria/DownloadPdf/' + maquinariaId+ "/" + documento , httpOptions)
        .toPromise()
        res => {
          return res.json().results;
        }
    }
  }

