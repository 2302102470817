import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BaseService } from "./base.service";
import { Configuracion } from "../models/configuracion.model";

@Injectable({
  providedIn: "root",
})
export class ConfiguracionService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  update(body: Configuracion) {
    return this.http
      .put<Configuracion>(this.baseUrl + "api/Configuracion/Update", body)
      .pipe(
        map((res) => {
          console.log("");
          return res;
        })
      );
  }

  getById(id: number) {
    return this.http.get<any>(this.baseUrl + "api/Configuracion/GetById/" + id);
  }
}
