import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
/** footer component*/
export class FooterComponent {
    /** footer ctor */
    constructor() {

    }
}