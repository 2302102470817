import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NovedadService } from '../services/novedad.service';
import { ContactoService } from '../services/contacto.service';
import { EventoService } from '../services/evento.service';
import { ContactosComponent } from '../../contactos/contactos.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ImportService } from '../services/import.service';
import { AppRoutingModule } from '../../../app/app-routing.module';
import { NovedadesComponent } from '../../novedades/novedad-list/novedades.component';
import { NovedadComponent } from '../../novedades/novedad-form/novedad.component';
import { EventoListComponent } from '../../eventos/evento-list/evento-list.component';
import { EventoFormComponent } from '../../eventos/evento-form/evento-form.component';
import { ComponentModule } from 'src/app/shared/components/component.module';
import { TiponovedadFormComponent } from 'src/app/tipoNovedad/tiponovedad-form/tiponovedad-form.component';
import { TiponovedadListComponent } from 'src/app/tipoNovedad/tiponovedad-list/tiponovedad-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  imports: [

    CommonModule,
    AppRoutingModule,
    NgxDatatableModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TabsModule.forRoot(),
    ComponentModule
  ],
  exports:
    [
      NovedadesComponent,
      NovedadComponent
    ],
  providers:
    [
      NovedadService,
      ContactoService,
      EventoService,
      ImportService
    ],

  declarations:
    [
      NovedadesComponent,
      NovedadComponent,
      EventoListComponent,
      EventoFormComponent,
      ContactosComponent,
      TiponovedadFormComponent,
      TiponovedadListComponent
    ]
})


export class NovedadModule {
}
