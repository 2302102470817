import { Injectable } from '@angular/core';
import { EmpresaService } from './empresa.service';



@Injectable({
  providedIn: 'root'
})
export class ImportService extends EmpresaService {
  serviceUrl = 'api/Novedad/';

  updateFileNoticia(fileToUpload: File, id: string) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl  + 'api/Novedad/UploadImagen/' + id, formData, { reportProgress: true, observe: 'events' });

  }

  updateFileNMaquina(fileToUpload: File, id: string) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl + 'api/Maquinaria/UploadImagen/' + id, formData, { reportProgress: true, observe: 'events' });

  }

  updateFileTanque(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl +  this.serviceUrl + '/UploadTanque', formData, { reportProgress: true, observe: 'events' });

  }

  updateFileBoca(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl +  this.serviceUrl + '/UploadBoca', formData, { reportProgress: true, observe: 'events' });

  }

  updateFileUsuario(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl +  this.serviceUrl + '/UploadUsuario', formData, { reportProgress: true, observe: 'events' });

  }

  updateFileVehiculo(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl +  this.serviceUrl + '/UploadVehiculo', formData, { reportProgress: true, observe: 'events' });

  }

  updateFileTransaccion(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl +  this.serviceUrl + '/UploadTransaccion', formData, { reportProgress: true, observe: 'events' });

  }

  updateFilePeaje(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl +  this.serviceUrl + '/UploadPeaje', formData, { reportProgress: true, observe: 'events' });

  }

  updateProfile(fileToUpload: File) {

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.baseUrl + 'api/User/upload', formData, { reportProgress: true, observe: 'events' });

  }


}
