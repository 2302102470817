import { Injectable } from '@angular/core';

import { DeleteConfirmDialogComponent } from 'src/app/shared/components/delete-confirm-dialog/delete-confirm-dialog.component';


@Injectable({ providedIn: 'root' })

export class DialogService {

 /*  constructor(private dialog: MatDialog) {
  }

  openConfirmDialog(msg){
    return this.dialog.open(DeleteConfirmDialogComponent,{
       width: '390px',
       panelClass: 'confirm-dialog-container',
       disableClose: true,
       position: { top: "10px" },
       data :{
         message : msg
       }
     });
   } */
}
