import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';


import { BrowserModule } from '@angular/platform-browser';

import { DeleteConfirmDialogComponent } from './delete-confirm-dialog/delete-confirm-dialog.component';
import { DialogService } from '../../../app/Core/services/dialog.service';
import { AlertService } from '../../core/services/alert.service';
import { UploadComponent } from './upload/upload.component';
import { ImportService } from '../../core/services/import.service';
import { AlertComponent } from './alert/alert.component';


@NgModule({
  imports: [
    BrowserModule,
    NgbAlertModule
  ],
  providers: [DialogService, AlertService, ImportService],
  entryComponents: [DeleteConfirmDialogComponent, AlertComponent,UploadComponent],
  declarations: [AlertComponent, DeleteConfirmDialogComponent,UploadComponent],
  exports: [AlertComponent, DeleteConfirmDialogComponent,UploadComponent]
})
export class ComponentModule { }
