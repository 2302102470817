import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Evento } from 'src/app/core/models/evento.model';
import { EventoService } from 'src/app/core/services/evento.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-evento-list',
  templateUrl: './evento-list.component.html',
  styleUrls: ['./evento-list.component.scss'],
})
/** evento-list component*/
export class EventoListComponent implements OnInit {
  public rows: Evento[];
  public temp: Evento[];
  public loading: boolean;

  constructor(
    http: HttpClient,
    public apiService: EventoService,
    private messageService: NotificationService
  ) {}

  @ViewChild(DatatableComponent) table: DatatableComponent;

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.loading = true;

    this.apiService.getAll().subscribe(
      (data) => {
        this.rows = data;
        this.temp = [...data];
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  delete(id: number) {
    if (confirm('Esta seguro de eliminar el registro?')) {
      this.apiService.delete(id).subscribe(
        () => {
          this.getAll();
        },
        (error) => {
          this.messageService.error(error.error.message);
          this.loading = false;
        }
      );
    }
  }
}
