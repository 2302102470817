import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Formatter } from 'src/app/core/helpers/formatter';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { TipoNovedad } from 'src/app/core/models/tipoNovedad.model';
import { TipoNovedadService } from 'src/app/core/services/tiponovedad.service';

@Component({
  selector: 'app-tiponovedad-form',
  templateUrl: './tiponovedad-form.component.html',
  styleUrls: ['./tiponovedad-form.component.css']
})

export class TiponovedadFormComponent implements OnInit {

  /** novedad ctor */
  formGroup: FormGroup;
  title: string = 'Nueva';
  id: number;
  errorMessage: any;
  submitted = false;
  idiomas: any[];
  formatter: Formatter;
  ckeConfig: any;
  public loading: boolean;

  constructor(private fb: FormBuilder,
    private apiService: TipoNovedadService,
    private router: Router,
    private avRoute: ActivatedRoute,
    private calendar: NgbCalendar
  ) {

    if (this.avRoute.snapshot.params['id']) {
      this.id = this.avRoute.snapshot.params['id'];
    }
    this.createForm();

  }

  createForm() {

    this.formGroup = this.fb.group({
      'id': [0],
      'nombre': ['', Validators.required],
      'idiomaId': [1]
    });
    this.getIdiomas();
  }

  get f() { return this.formGroup.controls; }


  ngOnInit() {

    if (this.id > 0) {
      this.loading = true;
      this.title = 'Editar';

      this.apiService.getById(this.id).subscribe(
        (resp: any) => {
          const model = (resp as TipoNovedad);
          console.log(model);
          this.setForm(model);
        },
        error => {
          this.errorMessage = error;
          console.log(error);
        },
        () => this.loading = false);
    }
  }

  setForm(form: TipoNovedad) {
    this.formGroup.get('id').setValue(form.id);
    this.formGroup.get('nombre').setValue(form.nombre);
    this.formGroup.get('idiomaId').setValue(form.idiomaId);
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (this.title == 'Nueva') {
      this.loading = true;
      this.apiService.add(this.formGroup.value).subscribe(
        () => {
          this.returnUrl();
        },
        error => this.errorMessage = error,
        () => this.loading = false
      );
    } else if (this.title == 'Editar') {
      this.loading = true;
      this.apiService.update(this.formGroup.value).subscribe(
        () => {
          this.returnUrl();
        },
        error => this.errorMessage = error,
        () => this.loading = false
      );
    }
  }

  returnUrl() {
    this.router.navigate(['/tiponovedades']);
  }

  cancel() {
    this.returnUrl();
  }


  getIdiomas() {
    this.apiService.getIdiomas().subscribe(
      data => {
        this.idiomas = data;
      }
    );
  }
}
