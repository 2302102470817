import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Novedad } from '../../core/models/novedad.model';
import { NovedadService } from '../../core/services/novedad.service';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.css'],
})
/** novedades component*/
export class NovedadesComponent implements OnInit {
  public rows: Novedad[];
  public temp: Novedad[];
  public loading: boolean;

  constructor(
    http: HttpClient,
    private apiService: NovedadService,
    private messageService: NotificationService
  ) {}

  @ViewChild(DatatableComponent) table: DatatableComponent;

  ngOnInit() {
    document.body.style.backgroundColor = '#eee';
    this.getAll();
  }

  getAll() {
    this.loading = true;

    this.apiService.getAll().subscribe(
      (data) => {
        this.rows = data;
        this.temp = [...data];
        this.loading = false;
      },
      (error) => (this.loading = false)
    );
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d) {
      return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  delete(id: number) {
    if (confirm('Esta seguro de eliminar el registro?')) {
      this.apiService.delete(id).subscribe(
        () => {
          this.getAll();
        },
        (error) => {
          this.messageService.error(error.error.message);
          this.loading = false;
        }
      );
    }
  }
}
