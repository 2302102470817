import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "../../../app/app-routing.module";
import { RolService } from "../services/rol.service";
import { UsuarioService } from "../services/usuario.service";
import { RolesComponent } from "../../roles/roles.component";
import { RolComponent } from "../../roles/rol.component";
import { UsuariosComponent } from "../../usuarios/usuarios.component";
import { UsuarioComponent } from "../../usuarios/usuario.component";
import { LoginComponent } from "../../Auth/login/login.component";
import { LoginService } from "../services/login.service";

import { ComponentModule } from "../../shared/components/component.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ConfiguracionService } from "../services/configuracion.service";
import { ConfiguracionComponent } from "src/app/configuracion/configuracion.component";

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentModule,
  ],
  exports: [],
  providers: [RolService, UsuarioService, LoginService, ConfiguracionService],
  declarations: [
    RolesComponent,
    RolComponent,
    UsuariosComponent,
    UsuarioComponent,
    LoginComponent,
    ConfiguracionComponent,
  ],
})
export class AdminModule {}
