/**
 * Created by xavi on 5/16/17.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserSession } from "../models/UserSession.model";



@Injectable({ providedIn: 'root' })
export class StorageService {
  private loggedIn = new BehaviorSubject<UserSession>(null);

  constructor() {
    if (localStorage.hasOwnProperty('currentUserMT') == true) {
      const session = JSON.parse(localStorage.getItem('currentUserMT'));
      this.loggedIn.next(session);
    }
  }

  public get GetLogin(): UserSession { //TODO: CHANGE USER
    return this.loggedIn.value;
  }

  getUserLoggedIn(): UserSession {
    return this.loggedIn.value;
  }



  setCurrentSession(session: any): void {
    localStorage.setItem('currentUserMT', JSON.stringify(session));
    this.loggedIn.next(session);
  }
  logout() {
    localStorage.removeItem('currentUserMT');
    this.loggedIn.next(null);
  }

  setUserLoggedIn(user: UserSession) {
    localStorage.setItem('currentUserMT', JSON.stringify(user));
  }
}
