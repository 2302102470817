import { Component } from '@angular/core';

@Component({
    selector: 'app-home-layout',
    templateUrl: './home-layout.component.html',
    styleUrls: ['./home-layout.component.css']
})
/** home.layout component*/
export class HomeLayoutComponent {
    /** home.layout ctor */
    constructor() {

    }
}