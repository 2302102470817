import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../core/services/storage.service';
import { UserSession } from '../../core/models/UserSession.model';
import { LoginService } from '../../core/services/login.service';
import { AlertService } from '../../core/services/alert.service';

@Component({
  templateUrl: 'login.component.html',
  selector: 'app-login'
})

export class LoginComponent implements OnInit {


  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errors: string;
  isRequesting: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: LoginService,
    private alertService: AlertService,
    private storageService: StorageService
  )
  {

  }

  ngOnInit() {
    document.body.style.backgroundColor = "#4AB33E";
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
 
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.apiService.login(this.loginForm.value).subscribe(
      (resp: UserSession) => {
        this.correctLogin(resp);
      },
      error => {
        this.alertService.error(error);
        this.errors = error;
        this.loading = false;
      }
    );
  }

  private correctLogin(data: UserSession) {
   this.storageService.setCurrentSession(data);
    console.log(data);
    this.router.navigate(['/novedades']);
  }
}
