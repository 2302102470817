import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Formatter } from 'src/app/core/helpers/formatter';
import { Evento } from 'src/app/core/models/evento.model';
import { EventoService } from 'src/app/core/services/evento.service';

@Component({
  selector: 'app-evento-form',
  templateUrl: './evento-form.component.html',
  styleUrls: ['./evento-form.component.scss'],
})
/** evento-form component*/
export class EventoFormComponent  implements OnInit {
  /** novedad ctor */
  formGroup: FormGroup;
  title = 'Nueva';
  id = 0;
  errorMessage: any;
  submitted = false;
  idiomas: any[];
  model: Evento;
  url: any;
  formatter: Formatter;
  ckeConfig: any;
  mycontent: string;
  log = '';
  public loading: boolean;

  constructor(
    private fb: FormBuilder,
    private apiService: EventoService,
    private router: Router,
    private avRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    public datePipe: DatePipe
  ) {
    this.formatter = new Formatter(this.datePipe);

    if (this.avRoute.snapshot.params['id']) {
      this.id = this.avRoute.snapshot.params['id'];
    }
    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      id: [0],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      fecha: ['', Validators.required],
      fecha_Evento: [this.calendar.getToday()],
      activo: [0],
      idiomaId: [0],
    });
    this.getIdiomas();
  }

  get f() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
    };

    if (this.id > 0) {
      this.loading = true;
      this.title = 'Editar';

      this.apiService.getById(this.id).subscribe(
        (resp: any) => {
          const model = resp as Evento;
          console.log(model);
          this.setForm(model);
        },
        (error) => {
          this.errorMessage = error;
          console.log(error);
        },
        () => (this.loading = false)
      );
    }
  }

  setForm(form: Evento) {
    this.formGroup.get('id').setValue(form.id);
    this.formGroup.get('nombre').setValue(form.nombre);
    this.formGroup.get('idiomaId').setValue(form.idiomaId);
    this.formGroup.get('direccion').setValue(form.direccion);
    this.formGroup.get('fecha').setValue(form.fecha);
    this.formGroup.get('activo').setValue(form.activo);
    this.formGroup
      .get('fecha_Evento')
      .setValue(this.formatter.FormatDate(form.fecha_Evento));
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const formValues = this.formGroup.value;

    if (this.id === 0) {
      this.loading = true;
      this.apiService.add(this.formGroup.value).subscribe(
        () => {
          this.router.navigate(['/eventos']);
        },
        (error) => (this.errorMessage = error),
        () => (this.loading = false)
      );
    } else {
      this.loading = true;
      this.apiService.update(this.formGroup.value).subscribe(
        () => {
          this.router.navigate(['/eventos']);
        },
        (error) => (this.errorMessage = error),
        () => (this.loading = false)
      );
    }
  }

  cancel() {
    this.router.navigate(['/eventos']);
  }

  getIdiomas() {
    this.apiService.getIdiomas().subscribe((data) => {
      this.idiomas = data;
    });
  }
}
