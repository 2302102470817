import { Component, OnInit } from '@angular/core';
import { UserSession } from '../../core/models/UserSession.model';
import { LoginService } from '../../core/services/login.service';
import { StorageService } from '../../core/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  [x: string]: any;
  isExpanded = false;
  status: boolean;
  usuario;
  user = null;
  currentUser: UserSession;

  constructor(
    private storageService: StorageService,
    private authenticationService: LoginService
  ) {
    this.currentUser = this.storageService.getUserLoggedIn();
  }
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUserMT'));
    if (this.currentUser != null) {
      this.user = this.currentUser;
    } else {
      this.user = '';
    }
  }

  public logout(): void {
    this.storageService.logout();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
