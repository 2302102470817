import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { StorageService } from './core/services/storage.service';
import { UserSession } from './core/models/UserSession.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  
})
export class AppComponent {
  currentUser: UserSession;

  constructor(
    private router: Router,
    private authenticationService: StorageService
  ) {
   // this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  get isAdmin() {
    return this.currentUser ;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
