import { Injectable, Inject } from '@angular/core';
import { Headers, Http} from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {  Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { Rol } from '../models/rol.model';
import { BaseService } from './base.service';




@Injectable({
  providedIn: 'root'
})
export class RolService extends BaseService {
    [x: string]: any;

 

  constructor(protected http: HttpClient) {
    super(http);
  }

  add(body: Rol) {

    return this.http.post<Rol>(this.baseUrl + 'api/Rol/Create', body)
      .pipe(
        map(res => {
          console.log(res.nombre);
          return res;
        }));
    
  }

  update(body: Rol) {
    return this.http.put<Rol>(this.baseUrl + 'api/Rol/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }
  
  delete(id: Number) {
     return this.http.delete(this.baseUrl + "api/Rol/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

 
  getById(id: number) {
    return this.http.get<Rol[]>('api/Rol/GetById/' + id, this.generateHeaders());
  }

  getAll() {
    return this.http.get<Rol[]>(this.baseUrl + 'api/Rol/GetAll', this.generateHeaders());
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
