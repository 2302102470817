import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { HttpClient } from '@angular/common/http';
import { EmpresaService } from '../../core/services/empresa.service';
import { ExcelService } from '../../core/helpers/ExcelService';
import { Empresa } from '../../core/models/empresa.model';


@Component({
  selector: 'app-empresa-list',
  templateUrl: './empresa-list.component.html',
  styleUrls: ['./empresa-list.component.scss']
})
/** empresa-list component*/
export class EmpresaListComponent {
  public rows: Empresa[];
  public temp: Empresa[];
  public loading: boolean;

  constructor(http: HttpClient, private apiService: EmpresaService, private excelService: ExcelService) { }

@ViewChild(DatatableComponent) table: DatatableComponent;


ngOnInit() {
  this.getAll();
}


getAll() {
  this.loading = true;
  this.apiService.getAll().subscribe(
    data => {
      this.rows = data;
      this.temp = [...data];
    },
    error => console.log(error),
    () => this.loading = false
  );
}


updateFilter(event) {
  const val = event.target.value.toLowerCase();
  const temp = this.temp.filter(
    function (d) {
      return (
        d.nombre.toLowerCase().indexOf(val) !== -1
        || d.telefono.toLowerCase().indexOf(val) !== -1
        || d.firma.toLowerCase().indexOf(val) !== -1
        || !val
      );
    }
  );
  this.rows = temp;
  this.table.offset = 0;
}

delete (id: number) {
  if (confirm('Esta seguro de eliminar el registro?')) {
    this.apiService.delete(id).subscribe(
      res => this.getAll()
    );
  }
}


exportAsXLSX(): void {
  let newJson = this.rows.map(
    rec => {
      return {
        'Firma': rec.firma,
        'Usuario': rec.nombre,
        'CUIT': rec.cuit,
        'Teléfono': rec.telefono,
        'Email': rec.email
      }
    }
  );
  this.excelService.exportAsExcelFile(newJson, 'usuarios');
}

}
