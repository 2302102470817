import { DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
//Modules///
import { AppRoutingModule } from "../app/app-routing.module";
//components///
import { AppComponent } from "./app.component";
import { AuthorizatedGuard } from "./Auth/auth.guard";
import { ExcelService } from "./core/helpers/ExcelService";
import { AdminModule } from "./core/modules/admin.module";
import { EmpresaModule } from "./core/modules/empresa.module";
import { MaquinaModule } from "./core/modules/maquinas.module";
import { NovedadModule } from "./core/modules/novedades.module";
import { StorageService } from "./core/services/storage.service";
import { HomeComponent } from "./home/home/home.component";
import { ComponentModule } from "./shared/components/component.module";
import { FooterComponent } from "./shared/footer/footer.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FrontEndLayoutComponent } from "./shared/layout/front-end-layout/front-end-layout.component";
import { HomeLayoutComponent } from "./shared/layout/home.layout/home-layout.component";
import { LoginLayoutComponent } from "./shared/layout/login.layout/login-layout.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    FrontEndLayoutComponent,
  ],
  exports: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    AdminModule,
    NovedadModule,
    EmpresaModule,
    MaquinaModule,
    ComponentModule,
    ToastrModule.forRoot(),
  ],

  providers: [DatePipe, StorageService, ExcelService, AuthorizatedGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
