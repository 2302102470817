import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers, Http } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Usuario } from '../models/usuario.model';
import { Rol } from '../models/rol.model';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseService {

  add(body: Usuario) {
 return this.http.post<Usuario>(this.baseUrl + 'api/Usuario/Create', body)
      .pipe(
        map(res => {
          console.log(res.nombre);
          return res;
        }));
}
  


  update(body: Usuario) {
    return this.http.put<Usuario>(this.baseUrl + 'api/Usuario/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  delete(id: Number) {
    return this.http.delete(this.baseUrl + "api/Usuario/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }


  getById(id: number) {
    return this.http.get<any>(this.baseUrl +'api/Usuario/GetById/' + id);
  }

  getAll() {
    return this.http.get<Usuario[]>(this.baseUrl + 'api/Usuario/GetAll');
  }

  getRoles() {
    return this.http.get<Rol[]>(this.baseUrl +'api/Rol/GetAll');
  }
}
