import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from './base.service';
import { Empresa } from '../models/empresa.model';


@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends BaseService {
  
  add(body: Empresa) {
    return this.http.post<Empresa>(this.baseUrl + 'api/Empresa/Create', body)
      .pipe(
        map(res => {
          console.log(res.nombre);
          return res;
        }));
  }



  update(body: Empresa) {
    return this.http.put<Empresa>(this.baseUrl + 'api/Empresa/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  delete(id: Number) {
    return this.http.delete(this.baseUrl + "api/Empresa/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }


  getById(id: number) {
    return this.http.get<any>(this.baseUrl + 'api/Empresa/GetById/' + id);
  }

  getAll() {
    return this.http.get<any>(this.baseUrl + 'api/Empresa/GetAll');
  }

  getLocalidades() {
    return this.http.get<any>(this.baseUrl +'api/Empresa/GetLocalidades');
  }
}
