import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Formatter } from '../../../app/core/helpers/formatter';
import { Novedad } from '../../../app/core/models/novedad.model';
import { NovedadService } from '../../../app/core/services/novedad.service';

@Component({
  selector: 'app-novedad',
  templateUrl: './novedad.component.html',
  styleUrls: ['./novedad.component.css'],
  providers: [DatePipe]
})
/** novedad component*/

export class NovedadComponent implements OnInit {

  /** novedad ctor */
  formGroup: FormGroup;
  title = 'Nueva';
  id: number;
  errorMessage: any;
  submitted = false;
  idiomas: any[];
  tipos: any[];
  images: any[];
  url: any;
  formatter: Formatter;
  ckeConfig: any;
  master = 'Novedad';
  @ViewChild('myckeditor') ckeditor: any;

  fileName: string;
  public response: { 'fileName': '' };

  public loading: boolean;

  constructor(private fb: FormBuilder,
    private apiService: NovedadService,
    private router: Router,
    private avRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    public datePipe: DatePipe) {

    this.formatter = new Formatter(this.datePipe);

    if (this.avRoute.snapshot.params['id']) {
      this.id = this.avRoute.snapshot.params['id'];
    }
    this.createForm();

  }

  createForm() {

    this.formGroup = this.fb.group({
      'id': [0],
      'nombre': ['', Validators.required],
      'descripcionCorta': ['', Validators.required],
      'descripcion': new FormControl(this.ckeditor, [Validators.required]),
      'imagen': [''],
      'fecha_Alta': [this.calendar.getToday()],
      'fecha_Desde_Notificacion': [this.calendar.getToday()],
      'fecha_Hasta_Notificacion': [this.calendar.getToday()],
      'activo': [1],
      'idiomaId': [1],
      'tipoNovedadId': [0]
    });
    this.getCombos();


  }

  get f() { return this.formGroup.controls; }


  ngOnInit() {

    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };

    if (this.id > 0) {
      this.loading = true;
      this.title = 'Editar';

      this.apiService.getById(this.id).subscribe(
        (resp: any) => {
          const model = (resp as Novedad);
          console.log(model);
          this.setForm(model);
        },
        // tslint:disable-next-line: no-shadowed-variable
        error => {
          this.errorMessage = error;
          console.log(error);
        },
        () => this.loading = false);
    }
  }

  setForm(form: Novedad) {
    this.formGroup.get('id').setValue(form.id);
    this.formGroup.get('nombre').setValue(form.nombre);
    this.formGroup.get('idiomaId').setValue(form.idiomaId);
    this.formGroup.get('descripcion').setValue(form.descripcion);
    this.formGroup.get('descripcionCorta').setValue(form.descripcionCorta);
    this.formGroup.get('activo').setValue(form.activo);
    this.formGroup.get('tipoNovedadId').setValue(form.tipoNovedadId);
    this.formGroup.get('fecha_Desde_Notificacion').setValue(this.formatter.FormatDate(form.fecha_Desde_Notificacion));
    this.formGroup.get('fecha_Hasta_Notificacion').setValue(this.formatter.FormatDate(form.fecha_Hasta_Notificacion));
    this.formGroup.get('fecha_Alta').setValue(this.formatter.FormatDate(form.fecha_Alta));
    this.getImagenes(form.id);
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const formValues = this.formGroup.value;
    formValues['fecha_Alta'] = this.formatter.formatFechaJ(this.formGroup.controls['fecha_Alta'].value);
    formValues['imagen'] = this.url;

    if (this.title === 'Nueva') {
      this.loading = true;
      this.apiService.add(this.formGroup.value).subscribe(
        data => {
          this.router.navigate(['/novedades']);
        },
        // tslint:disable-next-line: no-shadowed-variable
        error => this.errorMessage = error,
        () => this.loading = false
      );
    } else if (this.title === 'Editar') {
      this.loading = true;
      this.apiService.update(this.formGroup.value).subscribe(
        data => {
          this.router.navigate(['/novedades']);
        },
        // tslint:disable-next-line: no-shadowed-variable
        error =>
          this.errorMessage = error,
        () => this.loading = false
      );
    }
  }


  public uploadFinished = (event) => {
    this.response = event;
    this.fileName = this.response.fileName;
    this.getImagenes(this.id);
  }



  cancel() {
    this.router.navigate(['/novedades']);
  }


  getCombos() {
    this.apiService.getIdiomas().subscribe(
      data => {
        this.idiomas = data;
      }
    );

    this.apiService.getTipos().subscribe(
      data => {
        this.tipos = data;
      }
    );
  }
  getImagenes(id: number) {
    this.apiService.getImagenes(id).subscribe(
      data => {
        this.images = data;
      }
    );
  }
  /*
    onSelectFile(event) {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
  
        reader.readAsDataURL(event.target.files[0]); // read file as data url
  
        reader.onload = (event) => { // called once readAsDataURL is completed
          this.url = event.currentTarget;
          this.url = this.url.result;
        }
      }
    } */

  eliminarFoto(id: number) {
    if (confirm('Esta seguro de eliminar el registro?')) {
      this.apiService.deleteImagen(id).subscribe(res => {
        this.getImagenes(this.id);
      });
    }
  }
  marcarPrincipal(id: number) {
    if (confirm('Esta seguro de seleccionar esta foto como Portada?')) {
      this.apiService.marcarPrincipal(id).subscribe(res => {
        this.getImagenes(this.id);
      });
    }
  }
}
