import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Contacto } from '../core/models/contacto.model';
import { ContactoService } from '../core/services/contacto.service';
import { ExcelService } from '../core/helpers/ExcelService';
import 'rxjs';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.scss']
})
/** contactos component*/
export class ContactosComponent {
  public rows: Contacto[];
  public temp: Contacto[];
  public fileUrl;
  public loading: boolean;


  constructor(http: HttpClient,
    private apiService: ContactoService,
    private excelService: ExcelService) { }

  @ViewChild(DatatableComponent) table: DatatableComponent;



  ngOnInit() {
    this.getAll();
  }


  getAll() {
    this.loading = true;
    this.apiService.getAll().subscribe(
      (data: Contacto[]) => {
        this.rows = data;
        this.temp = [...data];
      },
      error => console.log(error),
      () => this.loading = false
    );
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.asunto.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  exportAsXLSX(): void {

    this.apiService.ExportExcel().subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' });
      saveAs(blob, 'contactos.xls');
    });

  }

}
