import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})

export class BaseService {

  public baseUrl = '';

  constructor(protected http: HttpClient) {

    this.baseUrl = environment.settings.backend;
    console.log(this.baseUrl);


  }


  getMethod(url: string) {
    return this.http.get(this.baseUrl + url)
  }

  postMethod(url: string, body: any, options: any) {
    return this.http.post(this.baseUrl + url, body, options);
  }

  deleteMethod(url: string, options: any) {
    return this.http.delete(this.baseUrl + url, options);
  }


  protected handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

      errorMessage = error.error.message;
    }

    return throwError(errorMessage);
  }
}
