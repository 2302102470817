import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { HttpClient } from '@angular/common/http';
import { Rol } from '../core/models/rol.model';
import { RolService } from '../core/services/rol.service';




@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.css']
})

/** rol component*/
export class RolesComponent {

  public rows: Rol[];
  public temp: Rol[];

  constructor(http: HttpClient, private apiService: RolService) {}

  @ViewChild(DatatableComponent) table: DatatableComponent;


  ngOnInit() {
    this.getAll();
  }
  

  getAll() {
    this.apiService.getAll()
      .subscribe(data => {
        this.rows = data;
        this.temp = [...data];
      });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  delete(id: number) {
    if (confirm('Esta seguro de eliminar el registro?')) {
      this.apiService.delete(id).subscribe(res => {
        this.getAll();
      });
    }
  }
}



