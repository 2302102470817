import { Component, OnInit, EventEmitter, Output, Input, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EmpresaService } from '../../core/services/empresa.service';
import { Localidad } from 'src/app/core/models/localidad.model';


@Component({
  selector: 'app-empresa-form',
  templateUrl: './empresa-form.component.html',
  styleUrls: ['./empresa-form.component.scss']
})
/** empresa-form component*/
export class EmpresaComponent {

  registerForm: FormGroup;
  title: string = "Nuevo";
  id: number;
  errorMessage: any;
  submitted = false;
  localidades: Localidad[];
  public loading: boolean;

  constructor(private fb: FormBuilder, private apiService: EmpresaService, private router: Router, private avRoute: ActivatedRoute) {

    if (this.avRoute.snapshot.params["id"]) {
      this.id = this.avRoute.snapshot.params["id"];
    }

    this.registerForm = this.fb.group({
      id: 0,
      nombre: ['', Validators.required],
      firma: ['', Validators.required],
      cuit: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      telefono: ['', Validators.required],
      localidadId: 0,
      direccion: '',
      numero: '',
      activo: true,
      fechaAlta: null,
      localidad: null
    })
  }


  ngOnInit() {
    this.loading = true;

    this.apiService.getLocalidades().subscribe(
      data => {
        this.localidades = data;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );

    if (this.id > 0) {
      this.title = "Editar";
      this.apiService.getById(this.id).subscribe(
        resp => this.registerForm.setValue(resp),
        error => console.log(error),
        () => this.loading = false
      );
    }
  }


  get f() {
    return this.registerForm.controls;
  }


  save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    if (this.title == "Nuevo") {
      this.loading = true;
      this.apiService.add(this.registerForm.value).subscribe(
        data => this.router.navigate(['/empresas']),
        error => this.errorMessage = error,
        () => this.loading = false
      );
    }
    else if (this.title == "Editar") {
      this.loading = true
      this.apiService.update(this.registerForm.value).subscribe(
        data => this.router.navigate(['/empresas']),
        error => this.errorMessage = error,
        () => this.loading = false
      );
    }
  }


  cancel() {
    this.router.navigate(['/empresas']);
  }
}
