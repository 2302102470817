import { Component, OnInit, ViewChild } from '@angular/core';
import { TipoNovedad } from 'src/app/core/models/tipoNovedad.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { HttpClient } from '@angular/common/http';
import { TipoNovedadService } from 'src/app/core/services/tiponovedad.service';

@Component({
  selector: 'app-tiponovedad-list',
  templateUrl: './tiponovedad-list.component.html',
  styleUrls: ['./tiponovedad-list.component.css']
})

  export class TiponovedadListComponent implements OnInit {

    public rows: TipoNovedad[];
    public temp: TipoNovedad[];
    public loading: boolean;

    constructor(http: HttpClient, public apiService: TipoNovedadService) { }


    @ViewChild(DatatableComponent) table: DatatableComponent;


    ngOnInit() {
        this.getAll();
    }

    getAll() {
        this.loading = true;

        this.apiService.getAll().subscribe(
            data => {
                this.rows = data;
                this.temp = [...data];
                this.loading = false;
            },
            () => this.loading = false);
    }

   delete(id: number) {
        if (confirm('Esta seguro de eliminar el registro?')) {
            this.apiService.delete(id).subscribe(() => {
                this.getAll();
            });
        }
    }
}
