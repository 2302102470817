import { Component, OnInit, Output, Input, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { RolService } from '../core/services/rol.service';


@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html'
})

/** rol component*/
export class RolComponent implements OnInit {

  registerForm: FormGroup;
  title: string = "Nuevo";
  id: number;
  errorMessage: any;
  submitted = false;


  constructor(private fb: FormBuilder, private apiService: RolService, private router: Router, private avRoute: ActivatedRoute) {

    if (this.avRoute.snapshot.params["id"]) {
      this.id = this.avRoute.snapshot.params["id"];
    }

    this.registerForm = this.fb.group({
      id: 0,
      nombre: ['', Validators.required]
     })
  }

  ngOnInit() {
    if (this.id > 0) {
      this.title = "Editar";
      this.apiService.getById(this.id)
        .subscribe(resp => this.registerForm.setValue(resp)
          , error => this.errorMessage = error);
    }
  }

  get f() { return this.registerForm.controls; }


  save() {

    this.submitted = true;

  
    if (this.registerForm.invalid) {
      return;
    }


    if (this.title == "Nuevo") {
      this.apiService.add(this.registerForm.value)
        .subscribe((data) => {
          this.router.navigate(['/roles']);
        }, error => this.errorMessage = error)
    }
    else if (this.title == "Editar") {
      this.apiService.update(this.registerForm.value)
        .subscribe((data) => {
          this.router.navigate(['/roles']);
        }, error => this.errorMessage = error)
    }
  }

  cancel() {
    this.router.navigate(['/roles']);
  }

 
}
