import { Component } from '@angular/core';

@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.css']
})
/** login.layout component*/
export class LoginLayoutComponent {
    /** login.layout ctor */
    constructor() {

    }
}