import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NotificationService } from "src/app/core/services/notification.service";
import { TipoMaquinaria } from "../../core/models/tipoMaquinaria.model";
import { TipoMaquinariaService } from "../../core/services/tipomaquinaria.service";

@Component({
  selector: "app-tipomaquinaria-list",
  templateUrl: "./tipomaquinaria-list.component.html",
  styleUrls: ["./tipomaquinaria-list.component.scss"],
})
/** tipomaquinaria-list component*/
export class TipomaquinariaListComponent implements OnInit {
  public rows: TipoMaquinaria[];
  public temp: TipoMaquinaria[];
  public loading: boolean;

  constructor(
    http: HttpClient,
    public apiService: TipoMaquinariaService,
    private messageService: NotificationService
  ) {}

  @ViewChild(DatatableComponent) table: DatatableComponent;

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.loading = true;

    this.apiService.getAll().subscribe(
      (data) => {
        this.rows = data;
        this.temp = [...data];
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  delete(id: number) {
    if (confirm("Esta seguro de eliminar el registro?")) {
      this.apiService.delete(id).subscribe(
        () => {
          this.getAll();
        },
        (error) => {
          this.messageService.error(error.error.message);
          this.loading = false;
        }
      );
    }
  }
}
