import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Formatter } from '../../core/helpers/formatter';
import { ServiceCap } from '../../core/models/servicecap.model';
import { ServiceCapService } from '../../core/services/servicecap.service';




@Component({
    selector: 'app-servicecap-form',
    templateUrl: './servicecap-form.component.html',
    styleUrls: ['./servicecap-form.component.scss']
})
/** servicecap-form component*/
export class ServicecapFormComponent implements OnInit {
    /** servicecap-form ctor */

  /** novedad ctor */
  formGroup: FormGroup;
  title = 'Nueva';
  id: number;
  errorMessage: any;
  submitted = false;
  idiomas: any[];
  provincias: any[];
  model: ServiceCap;
  url: any;
  formatter: Formatter;
  ckeConfig: any;
  mycontent: string;
  log = '';
  @ViewChild('myckeditor') ckeditor: any;

  public loading: boolean;

  constructor(private fb: FormBuilder,
    private apiService: ServiceCapService,
    private router: Router,
    private avRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    public datePipe: DatePipe) {

    this.formatter = new Formatter(this.datePipe);

    if (this.avRoute.snapshot.params['id']) {
      this.id = this.avRoute.snapshot.params['id'];
    }
    this.createForm();

  }

  createForm() {

    this.formGroup = this.fb.group({
      'id': [0],
      'nombre': ['', Validators.required],
      'descripcionCorta': ['', Validators.required],
      'descripcion': new FormControl(this.ckeditor, [Validators.required]),
      'direccion': [''],
      'provincia': [''],
      'fecha_Alta': [this.calendar.getToday()],
      'latitud': [''],
      'longitud': [''],
      'email': [''],
      'activo': [0],
      'idiomaId': [0]
    });
  this.getCombos();
  }

get f() { return this.formGroup.controls; }


  ngOnInit() {

    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };

    if (this.id > 0) {
      this.loading = true;
      this.title = 'Editar';

      this.apiService.getById(this.id).subscribe(
        (resp: any) => {
          const model = (resp as ServiceCap);
          console.log(model);
          this.setForm(model);
        },
        error => {
          this.errorMessage = error;
          console.log(error);
        },
        () => this.loading = false);
    }
  }

  setForm(form: ServiceCap) {
    this.formGroup.get('id').setValue(form.id);
    this.formGroup.get('nombre').setValue(form.nombre);
    this.formGroup.get('idiomaId').setValue(form.idiomaId);
    this.formGroup.get('descripcion').setValue(form.descripcion);
    this.formGroup.get('descripcionCorta').setValue(form.descripcionCorta);
    this.formGroup.get('activo').setValue(form.activo);
    this.formGroup.get('direccion').setValue((form.direccion));
    this.formGroup.get('email').setValue((form.email));
    this.formGroup.get('latitud').setValue((form.latitud));
    this.formGroup.get('longitud').setValue((form.longitud));
    this.formGroup.get('provincia').setValue((form.provincia));
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }


    if (this.title === 'Nueva') {
      this.loading = true;
      this.apiService.add(this.formGroup.value).subscribe(
        () => {
          this.router.navigate(['/servicecaps']);
        },
        error => this.errorMessage = error,
        () => this.loading = false
      );
    } else if (this.title === 'Editar') {
      this.loading = true;
      this.apiService.update(this.formGroup.value).subscribe(
        () => {
          this.router.navigate(['/servicecaps']);
        },
        error => this.errorMessage = error,
        () => this.loading = false
      );
    }
  }


  cancel() {
    this.router.navigate(['/servicecaps']);
  }


  getCombos() {
    this.apiService.getIdiomas().subscribe(
      data => {
        this.idiomas = data;
      }
    );
    this.apiService.getProvincias().subscribe(
      data => {
        this.provincias = data;
      }
    );
  }

}
