import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Formatter } from 'src/app/core/helpers/formatter';
import { TipoMaquinaria } from '../../core/models/tipoMaquinaria.model';
import { TipoMaquinariaService } from '../../core/services/tipomaquinaria.service';


@Component({
    selector: 'app-tipomaquinaria-form',
    templateUrl: './tipomaquinaria-form.component.html',
    styleUrls: ['./tipomaquinaria-form.component.scss']
})
/** tipomaquinaria-form component*/
export class TipomaquinariaFormComponent implements OnInit {

    /** novedad ctor */
    formGroup: FormGroup;
    title = 'Nueva';
    id: number;
    errorMessage: any;
    submitted = false;
    idiomas: any[];
    formatter: Formatter;
    ckeConfig: any;
    public loading: boolean;

    constructor(private fb: FormBuilder,
        private apiService: TipoMaquinariaService,
        private router: Router,
        private avRoute: ActivatedRoute,
        public datePipe: DatePipe) {

        this.formatter = new Formatter(this.datePipe);

        if (this.avRoute.snapshot.params['id']) {
            this.id = this.avRoute.snapshot.params['id'];
        }
        this.createForm();

    }

    createForm() {

        this.formGroup = this.fb.group({
            'id': [0],
            'nombre': ['', Validators.required],
            'descripcion': ['', Validators.required],
            'idiomaId': [1]
        });
        this.getIdiomas();
    }

    get f() { return this.formGroup.controls; }


    ngOnInit() {

        this.ckeConfig = {
            allowedContent: false,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true
        };

        if (this.id > 0) {
            this.loading = true;
            this.title = 'Editar';

            this.apiService.getById(this.id).subscribe(
                (resp: any) => {
                    const model = (resp as TipoMaquinaria);
                    console.log(model);
                    this.setForm(model);
                },
                error => {
                    this.errorMessage = error;
                    console.log(error);
                },
                () => this.loading = false);
        }
    }

    setForm(form: TipoMaquinaria) {
        this.formGroup.get('id').setValue(form.id);
        this.formGroup.get('nombre').setValue(form.nombre);
        this.formGroup.get('idiomaId').setValue(form.idiomaId);
        this.formGroup.get('descripcion').setValue(form.descripcion);
    }

    onSubmit() {
        this.submitted = true;

        if (this.formGroup.invalid) {
            return;
        }

        if (this.title === 'Nueva') {
            this.loading = true;
            this.apiService.add(this.formGroup.value).subscribe(
                () => {
                    this.returnUrl();
                },
                error => this.errorMessage = error,
                () => this.loading = false
            );
        } else if (this.title === 'Editar') {
            this.loading = true;
            this.apiService.update(this.formGroup.value).subscribe(
                () => {
                    this.returnUrl();
                },
                error => this.errorMessage = error,
                () => this.loading = false
            );
        }
    }

    returnUrl() {
        this.router.navigate(['/tipomaquinarias']);
    }

    cancel() {
        this.returnUrl();
    }

    getIdiomas() {
        this.apiService.getIdiomas().subscribe(
            data => {
                this.idiomas = data;
            }
        );
    }
}
