import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { ImportService } from '../../../core/services/import.service';



@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  public progress: number;
  public message: string;
  public fileName: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() public onUploadFinished = new EventEmitter();
  @Input() id: string;
  @Input('master') masterName: string;


  constructor(private http: HttpClient,
    private apiService: ImportService) { }

  ngOnInit() {
  }



  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);


   
 
    if (this.masterName=="Novedad")  {
    
      this.apiService.updateFileNoticia(fileToUpload, this.id).subscribe(event => {

        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          /*  this.fileName = event.body.fileName; */
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
    }


    if (this.masterName == 'Maquinaria') {
      this.apiService.updateFileNMaquina(fileToUpload, this.id).subscribe(event => {

        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          /*  this.fileName = event.body.fileName; */
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
    }


  }
}
