import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Formatter } from '../../../app/core/helpers/formatter';
import { Maquinaria } from '../../core/models/maquinaria.model';
import { MaquinariaService } from '../../core/services/maquinaria.service';
import { TipoMaquinariaService } from '../../core/services/tipomaquinaria.service';

@Component({
  selector: 'app-maquinaria-form',
  templateUrl: './maquinaria-form.component.html',
  styleUrls: ['./maquinaria-form.component.scss'],
})
/** maquinaria-form component*/
export class MaquinariaFormComponent implements OnInit {
  /** novedad ctor */
  formGroup: FormGroup;
  title = 'Nueva';
  id = 0;
  errorMessage: any;
  submitted = false;
  idiomas: any[];
  tipos: any[];
  tipoMaquinarias: any[];
  images: any[];
  url: any;
  formatter: Formatter;
  ckeConfig: any;
  @ViewChild('myckeditor') ckeditor: any;
  fileData: File = null;
  fileDataManual: File = null;
  previewUrl: any = null;
  public response: { fileName: '' };
  urlFolleto = '';
  master = 'Maquinaria';

  public loading: boolean;

  constructor(
    private fb: FormBuilder,
    private apiService: MaquinariaService,
    private apiTipoService: TipoMaquinariaService,
    private router: Router,
    private avRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    public datePipe: DatePipe
  ) {
    this.formatter = new Formatter(this.datePipe);

    if (this.avRoute.snapshot.params['id']) {
      this.id = this.avRoute.snapshot.params['id'];
    }
    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      id: [0],
      tipoId: [0],
      nombre: ['', Validators.required],
      modelo: ['', Validators.required],
      descripcionCorta: [''],
      descripcion: new FormControl(this.ckeditor, [Validators.required]),
      imagen: [''],
      activo: [1],
      orden: [0],
      idiomaId: [1],
      estado: ['', Validators.required],
      urlManual: [''],
      urlFolleto: [''],
      urlPublicaManual: [''],
    });
    this.getCombos();
  }

  get f() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
    };

    if (this.id > 0) {
      this.loading = true;
      this.title = 'Editar';

      this.apiService.getById(this.id).subscribe(
        (resp: any) => {
          const model = resp as Maquinaria;
          console.log(model);
          this.setForm(model);
        },
        (error) => {
          this.errorMessage = error;
          console.log(error);
        },
        () => (this.loading = false)
      );
    }
  }

  setForm(form: Maquinaria) {
    this.formGroup.get('id').setValue(form.id);
    this.formGroup.get('nombre').setValue(form.nombre);
    this.formGroup.get('modelo').setValue(form.modelo);
    this.formGroup.get('idiomaId').setValue(form.idiomaId);
    this.formGroup.get('descripcion').setValue(form.descripcion);
    this.formGroup.get('descripcionCorta').setValue(form.descripcionCorta);
    this.formGroup.get('activo').setValue(form.activo);
    this.formGroup.get('tipoId').setValue(form.tipoId);
    this.formGroup.get('estado').setValue(form.estado);
    this.formGroup.get('urlManual').setValue(form.urlManual);
    this.formGroup.get('urlPublicaManual').setValue(form.urlPublicaManual);
    this.formGroup.get('urlFolleto').setValue(form.urlFolleto);
    this.formGroup.get('orden').setValue(form.orden);
    this.urlFolleto = form.urlFolleto;

    this.getImagenes(form.id);
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const formValues = this.formGroup.value;
    // formValues['imagen'] = this.url;

    if (this.title === 'Nueva') {
      this.loading = true;
      this.apiService.add(this.formGroup.value).subscribe(
        (data) => {
          this.NuevoFolleto(this.id);
          // this.NuevoManual(this.id);
          this.cancel();
        },
        (error) => (this.errorMessage = error),
        () => (this.loading = false)
      );
    } else if (this.id > 0) {
      this.loading = true;
      this.apiService.update(this.formGroup.value).subscribe(
        (data) => {
          this.NuevoFolleto(this.id);
          // this.NuevoManual(this.id);
          this.cancel();
        },
        (error) => (this.errorMessage = error),
        () => (this.loading = false)
      );
    }
  }

  public uploadFinished = (event) => {
    this.response = event;
    //  this.fileName = this.response.fileName;
    this.getImagenes(this.id);
  }
  cancel() {
    this.router.navigate(['/maquinarias']);
  }

  getCombos() {
    this.apiService.getIdiomas().subscribe((data) => {
      this.idiomas = data;
    });
    this.apiService.getTipos().subscribe((data) => {
      this.tipoMaquinarias = data;
    });

    this.apiTipoService.getAll().subscribe((data) => {
      this.tipos = data;
    });
  }
  getImagenes(id: number) {
    this.apiService.getImagenes(id).subscribe((data) => {
      this.images = data;
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.currentTarget;
        this.url = this.url.result;
      };
    }
  }

  eliminarFoto(id: number) {
    if (confirm('Esta seguro de eliminar el registro?')) {
      this.apiService.deleteImagen(id).subscribe((res) => {
        this.getImagenes(this.id);
      });
    }
  }
  marcarPrincipal(id: number) {
    if (confirm('Esta seguro de seleccionar esta foto como Portada?')) {
      this.apiService.marcarPrincipal(id).subscribe((res) => {
        this.getImagenes(this.id);
      });
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      // return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  fileManualProgress(fileInput: any) {
    this.fileDataManual = <File>fileInput.target.files[0];
    this.previewManual();
  }
  previewManual() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      // return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  NuevoFolleto(maquinariaId: number) {
    if (this.fileData != null) {
      this.apiService
        .updateDocumento(this.fileData, maquinariaId, 'Folleto')
        .subscribe((res) => {});
    }
  }

  NuevoManual(maquinariaId: number) {
    if (this.fileDataManual != null) {
      this.apiService
        .updateDocumento(this.fileDataManual, maquinariaId, 'Manual')
        .subscribe((res) => {});
    }
  }

  private onDownload(documento: string) {
    this.apiService.exportPdf(this.id, documento).then(
      (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const pwa = window.open(url);
      },
      (error) => {
        console.log('Something went wrong');
      }
    );
  }
}
