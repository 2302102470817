import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Headers, Http } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { Novedad } from '../models/novedad.model';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class NovedadService extends BaseService {
  [x: string]: any;



  constructor(protected http: HttpClient) {
    super(http);
  }

  add(body: Novedad) {

    return this.http.post<Novedad>(this.baseUrl + 'api/Novedad/Create', body)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }));

  }

  update(body: Novedad) {
    return this.http.put<Novedad>(this.baseUrl + 'api/Novedad/Update', body)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  delete(id: Number) {
    return this.http.delete(this.baseUrl + "api/Novedad/Delete/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }
  deleteImagen(id: Number) {
    return this.http.delete(this.baseUrl + "api/Novedad/DeleteImagen/" + id)
      .pipe(
        map(res => {
          console.log("");
          return res;
        }));
  }

  getById(id: number) {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetById/' + id);
  }
  
  getImagenes(id: number) {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetImagenes/' + id);
  }
  getAll() {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetAll');
  }
  marcarPrincipal(id: Number) {
    return this.http.delete(this.baseUrl + "api/Novedad/UpdateImagen/" + id)
    .pipe(
      map(res => {
        console.log("");
        return res;
      }));
}
 
  getIdiomas() {
    return this.http.get<any>(this.baseUrl + 'api/Novedad/GetIdiomas');
  }

  getTipos() {
    return this.http.get<any>(this.baseUrl + 'api/TipoNovedad/GetAll');
  }
}
