import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers, Http } from '@angular/http';
import { Observable } from 'rxjs';
import { catchError, retry, map, first } from 'rxjs/operators';

import { Usuario } from '../models/usuario.model';
import { UserSession } from "../models/UserSession.model";
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  

  public usuarioLogin: Usuario;
  usserLogged: UserSession;
  isUserLoggedIn: boolean;

  constructor(protected http: HttpClient) {
    super(http);
   // this.usuarioLogin = false;
  }


  setUserLoggedIn(user: UserSession) {
    this.isUserLoggedIn = true;
    this.usserLogged = user;
    localStorage.setItem('currentUser', JSON.stringify(user));

  }

  getUserLoggedIn() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  logout(){}
  
  login(body: UserSession) {

    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post<UserSession>(
        this.baseUrl + 'api/Login/Login',
        body, { headers: httpHeaders }
      )
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  };
  
}

