import { Component } from '@angular/core';

@Component({
    selector: 'app-front-end-layout',
    templateUrl: './front-end-layout.component.html',
    styleUrls: ['./front-end-layout.component.scss']
})
/** frontEnd.layout component*/
export class FrontEndLayoutComponent {
    /** frontEnd.layout ctor */
    constructor() {

    }
}